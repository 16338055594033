<template>
  <div>
    <div>
      <div class="m-2">
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            />
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  v-b-modal="'modal-add'"
                  variant="primary"
                >
                  <span class="text-nowrap">Add Slider</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row
          class="justify-content-center"
        >
          <b-col
            v-for="(v, i) in contactList"
            :key="i"
            cols="4"
          >
            <b-card>
              <template>
                <div>
                  <div class="text-center">
                    <img
                      v-if="v.image"
                      :src="v.image"
                      :alt="v.title"
                      width="200"
                    >
                  </div>
                  <h5 class="use-text-subtitle">
                    {{ v.title }}
                  </h5>
                  <div class="float-left">
                    {{ v.description }}
                  </div> <br>
                </div>
                <div>
                  <span
                    v-if="v.language"
                  >
                    <b-badge
                      variant="dark"
                      class="my-2"
                    >{{ v.language.name }}</b-badge>
                  </span>
                </div>
              </template>
              <template>
                <b-button
                  v-b-modal="'modal-edit'"
                  class="m-1"
                  variant="primary"
                  @click="editCat(v.id)"
                >
                  <feather-icon icon="EditIcon" />
                  <span> Edit </span>
                </b-button>
                <b-button @click="deleteSlider(v.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-button>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal
      v-if="show"
      id="modal-edit"
      centered
      title="Edit Contact"
      hide-footer
    >
      <b-form
        ref="myForm"
        class="p-2"
        @submit.enter.prevent=""
      >
        <b-form-group
          label="Title"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="catEdit.title"
            placeholder="Title"
          />
        </b-form-group>
        <b-form-group
          label="Description"
          label-for="cat_name"
        >
          <b-form-textarea
            id="cat_name"
            v-model="catEdit.description"
            placeholder="Description"
          />
        </b-form-group>
        <b-form-group
          id="fileInput"
        >
          <b-form-file
            accept="image/jpeg, image/png, image/gif"
            placeholder="select an Image"
            @change="onChangeEdit"
          />
          <template v-if="preview">
            <img
              :src="preview"
              class="img-fluid"
            >
          </template>
        </b-form-group>
        <select-language :selected-language="catEdit" />
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal()"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
            @click="updateCat(catEdit.id)"
          >
            save
          </b-button>
        </b-col>
      </b-form>
    </b-modal>
    <b-modal
      v-if="show"
      id="modal-add"
      centered
      title="Add Slider"
      hide-footer
    >
      <validation-observer
        ref="infoRules"
        tag="form"
      >
        <b-form
          ref="myForm"
          class="p-2"
          @submit.enter.prevent=""
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Title"
          >
            <b-form-group
              label="Title"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="addData.title"
                :state="getValidationState(validationContext)"
                placeholder="Title"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description"
          >
            <b-form-group
              label="Description"
              label-for="Description"
            >
              <b-form-textarea
                id="Description"
                v-model="addData.description"
                :state="getValidationState(validationContext)"
                placeholder="Description"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group
            id="fileInput"
          >
            <b-form-file
              v-model="featuredImage.image"
              :state="Boolean(featuredImage.image)"
              accept=".jpg, .png, .gif"
              placeholder="select an Image"
              @change="onChange"
            />
            <template v-if="preview">
              <img
                :src="preview"
                class="img-fluid"
              >
            </template>
          </b-form-group>
          <select-language :selected-language="addData" />
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!loader"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="addSlider()"
            >
              Add
            </b-button>
            <b-button
              variant="outline-danger"
              class="closeModal"
              @click="closeModal()"
            >
              Cancel
            </b-button>
            <b-button
              v-if="loader"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import { required } from '@validations'
import handleFormData from '@/common/compositions/handleFormData'
import selectLanguage from '@/common/components/SelectLanguage.vue'

Vue.use(VueSweetalert2)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    selectLanguage,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      editData: {},
      show: true,
      contactList: [],
      addData: {
        title: '',
        description: '',
        image: '',
        language_id: '',
      },
      preview: null,
      image: null,
      catEdit: {},
      featuredImage: {
        image: null,
        url: null,
      },
      validationErrors: {},
      loader: false,
    }
  },
  setup() {
    const { formData, setFormData } = handleFormData()
    const {
      getValidationState,
    } = formValidation()
    return {
      formData, setFormData, getValidationState,
    }
  },
  created() {
    this.getSliders()
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.addData.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    onChangeEdit(e) {
      // eslint-disable-next-line prefer-destructuring
      this.catEdit.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    closeModal() {
      this.addData = {
        title: '',
        description: '',
        image: '',
        language_id: '',
      }
      this.preview = null
      this.$bvModal.hide('modal-edit')
      this.$bvModal.hide('modal-add')
    },
    async getSliders() {
      await axios.get('get/slider').then(res => {
        this.contactList = res.data.success.data
      })
    },
    updateCat(id) {
      this.setFormData(this.catEdit)
      axios.post(`edit/slider/${id}`, this.formData).then(res => {
        if (res.status === 200) {
          this.getSliders()
          this.$bvModal.hide('modal-edit')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Edited Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.editData = {}
        }
      })
    },
    deleteSlider(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios.delete(`delete/slider/${id}`).then(res => {
            if (res.status === 200) {
              this.getSliders()()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Deleted Succesfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
            }
          })
        }
      })
    },
    addSlider() {
      this.$refs.infoRules.validate().then(success => {
        if (success) {
          this.loader = true
          this.setFormData(this.addData)
          axios.post('add/slider', this.formData).then(res => {
            if (res.status === 200) {
              this.getSliders()
              this.closeModal()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Added Succesfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
            }
          }).catch(err => {
            if (err) {
              Object.assign(this.validationErrors, err.response.data.errors)
            }
          }).finally(() => {
            this.loader = false
          })
        }
      })
    },
    editCat(id) {
      axios.get(`get/slider/${id}`).then(res => {
        this.catEdit = res.data.success
        this.preview = res.data.success.image
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
